import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import store from '@/store/index.js'
import i18n from '@/helpers/i18n';
import router from '@/router';
// import { USER_SERVICE } from "@/services/user.service.js";

export const IS_LOADING_STATUS = "loading";

export const API_HELPER = {
    abortController: new AbortController(),
    pathJoin,
    loggedApiCall,
    apiCall
};

function pathJoin(parts)
{
    return parts.map((part, i) => 
    {
        if (i === 0) {
          return part.toString().trim().replace(/[/]*$/g, '')
        } else {
          return part.toString().trim().replace(/(^[/]*|[/]*$)/g, '')
        }
    }).filter(x=>x.length).join('/');
}

/* Handles 1) request cancellation 2) refresh the token if the user is not logged in 3) fetch url 4) error handler */
function loggedApiCall(url, queryParams, requestOptions, canBeAborted)
{
    // Validate parameters
    if(!url || !requestOptions)
    {
        throw("The necessary info for the api call was not provided!");
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(requestOptions) }

    canBeAborted = (canBeAborted === undefined || canBeAborted === null) ? true : canBeAborted;

    // Validate if the user is logged in.
    let user = JSON.parse(localStorage.getItem('user'));
    if(!user || !user.data.accessToken)
    {
        store.dispatch('account/logout', null, { root: true });
    }

    // Check if token is expired. Return authorization header with jwt token and compare expired time with the next 10 minutes. The 10 minutes is just a buffer that we allow ourselves to refresh the token. 
    // var expiredTime = new Date(user.expired_time);

    // var limitTime = new Date();
    // limitTime.setMinutes(limitTime.getMinutes() + 10);
    // var limitTimeUtc = new Date(limitTime.getUTCFullYear(), limitTime.getUTCMonth(), limitTime.getUTCDate(), limitTime.getUTCHours(), limitTime.getUTCMinutes(), limitTime.getUTCSeconds());

    // if(expiredTime < limitTimeUtc)
    // {
    //     /* Refresh the token and try again */
    //     USER_SERVICE.refresh(user.data.refreshToken, user.data.profile.email)
    //     .then(user =>{
    //         // Update request options authorization
    //         console.log("Refreshed the user token!");
    //         requestOptions.Authorization = 'Bearer ' + user.data.refreshToken;
    //     })
    //     .catch((error) => 
    //     {
    //         USER_SERVICE.logout();
    //         router.push('/login');
    //         console.log(user.data.profile.email)
    //         return Promise.reject("Couldn't refresh the token. " + error);
    //     });
    // }

    // Build query parameters
    var fullUrl = getFullUrl(url, queryParams);

    // Complete the request options adding signal so that the request can be aborted.
    if(canBeAborted)
    {
        requestOptions.signal = API_HELPER.abortController.signal;
    }

    // Add current language to request
    if(!requestOptions.headers) 
    {
        requestOptions.headers = {};
    }
    requestOptions.headers["Accept-Language"] = i18n.locale;

    if(ENVIRONMENT_HELPER.isDev()) { console.log(fullUrl) }

    return fetch(fullUrl, requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) { console.log(response) }
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) { console.log(data) }
            if (!response.ok) {
                var errorMsg;
                switch(response.status)
                {
                    case 401:
                        {
                            errorMsg = "The token is not valid.";

                            store.dispatch('account/logout', null, { root: true });
                            break;
                        }
                    // case 401:
                    //     {
                    //         if (user.data.refreshToken)
                    //         {                           
                    //             USER_SERVICE.refresh(user.data.refreshToken, user.data.profile.email)
                    //             .then(user =>{
                    //                 // Update request options authorization
                    //                 if (user.data && user.data.accessToken) {
                    //                     // store user details and jwt token in local storage to keep user logged in between page refreshes
                    //                     localStorage.setItem('user', JSON.stringify(user));
                    //                 }
                    //                 console.log("Refreshed the user token!");
                    //                 requestOptions.Authorization = 'Bearer ' + user.data.accessToken;
                    //             })
                    //             .catch((error) => 
                    //             {
                    //                 console.log(error);
                    //                 USER_SERVICE.logout();
                    //                 router.push('/login');
                    //                 return Promise.reject("Couldn't refresh the token. " + error);
                    //             });
                    //         } else {
                    //             USER_SERVICE.logout();
                    //             router.push('/login');
                    //         }
                    //         break; 
                    //     }
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                        {
                            router.push('/error');
                            break;
                        }
                    default:
                        {
                            errorMsg = data;
                            break;
                        }
                }

                return Promise.reject(errorMsg);
            }
    
            return data;
        });        
    })
    .catch((err) => 
    {
        if (err.name === 'AbortError') 
        {
            console.log("The request was aborted!");
        }
        else
        {
            if(err && err.Errors)
            {
                return Promise.reject(err);
            }
            else
            {
                return Promise.reject("Something went wrong, please try again. If the problem persists contact the support.");
            }
        }
    });
}

/* Handles 1) request cancellation 2) fetch url 3) error handler */
function apiCall(url, queryParams, requestOptions, canBeAborted)
{

    // Validate parameters
    if(!url || !requestOptions)
    {
        throw("The necessary info for the api call was not provided!");
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(requestOptions) }

    canBeAborted = (canBeAborted === undefined || canBeAborted === null) ? true : canBeAborted;

    // Build query parameters
    var fullUrl = getFullUrl(url, queryParams);

    // Complete the request options adding signal so that the request can be aborted.
    if(canBeAborted)
    {
        requestOptions.signal = API_HELPER.abortController.signal;
    }

    if(!requestOptions.headers) 
    {
        requestOptions.headers = {};
    }
    requestOptions.headers["Accept-Language"] = i18n.locale;

    if(ENVIRONMENT_HELPER.isDev()) { console.log(fullUrl) }

    return fetch(fullUrl, requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) { console.log(response) }
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) { console.log(data) }
            if (!response.ok) {
                var errorMsg;
                switch(response.status)
                {                   
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                        {
                            router.push('/error');
                            break;
                        }
                    default:
                        {
                            errorMsg = data;
                            break;
                        }
                }

                return Promise.reject(errorMsg);
            }
    
            return data;
        });        
    })
    .catch((err) => 
    {
        if (err.name === 'AbortError') 
        {
            console.log("The request was aborted!");
        }
        else
        {
            if(err && err.Errors)
            {
                return Promise.reject(err);
            }
            else
            {
                return Promise.reject("Something went wrong, please try again. If the problem persists contact the support.");
            }
        }
    });
}

function getFullUrl(url, queryParams)
{
    var fullUrl = encodeURI(url);
    if(queryParams)
    {
        fullUrl += "?";
        for(var key in queryParams) 
        {
            var value = queryParams[key];

            if(Array.isArray(value))
            {
                value.forEach(function(item){
                    var encodedItem = encodeURIComponent(item);
                    fullUrl += `&${key}=${encodedItem}`;    
                });
            }
            else if(value || value == 0)
            {
                var encodedValue = encodeURIComponent(value);

                fullUrl += `&${key}=${encodedValue}`;
            }
        }
    }

    return fullUrl;
}