<template>
    <div class="image container d-flex align-items-center justify-content-center">
      <div class="image-content d-flex flex-column justify-content-center">
        <!-- Close -->
        <div class="close-button cursor-pointer d-flex justify-content-end">
          <p @click="closeClicked"><img src="@/assets/svg/close.svg" alt="image" class="w-100" loading="lazy" /><span>{{$t('close')}}</span></p>
        </div>
        <!-- image -->
        <vueper-slides ref="fullscreenGallery" class="no-shadow" fixed-height="80vh" :autoplay="false" :touchable="false" :visible-slides="1" :arrows="true" :bullets="false" fractions progress>
          <!-- content -->
            <template v-slot:arrow-left>
                <img src="@/assets/svg/arrow-left.svg" alt="" loading="lazy">
            </template>
            <template v-slot:arrow-right>
                <img src="@/assets/svg/arrow-right.svg" alt="" loading="lazy">
            </template>
          <vueper-slide v-for="(img, index) in gallery" :key="index" :image="joinPaths(img.image)"></vueper-slide>
        </vueper-slides>
      </div>
    </div>
  </template>
  
  <script>
  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'
  import { API_HELPER } from "@/helpers/api.js";
  
  export default {
    components: { VueperSlides, VueperSlide },
    props: {
      gallery: Array,
      startIndex: Number
    },
    data() {
      return {
        urlRoot: process.env.VUE_APP_URL_ROOT
      }
    },
    mounted() {
      if (this.$refs.fullscreenGallery) {
        this.$refs.fullscreenGallery.goToSlide(this.startIndex);
      }
    },
    methods: {
      closeClicked() {
        this.$emit("closeRequest");
      },
      isVideo(file) {
        let typeOfVideos = ['mp4', 'avi'];
        let path = file;
        return typeOfVideos.some(ext => path.includes(ext)) ? API_HELPER.pathJoin([this.urlRoot, path]) : null;
      },
      joinPaths(relativePath) {
        if (Array.isArray(relativePath)) {
          return relativePath.map(path => {
            const fullPath = API_HELPER.pathJoin([this.urlRoot, path]);
            return fullPath;
          });
        }
        const fullPath = API_HELPER.pathJoin([this.urlRoot, relativePath]);
        return fullPath;
      },
    },
    watch: {
      startIndex() {
        if (this.$refs.fullscreenGallery) {
          this.$refs.fullscreenGallery.goToSlide(this.startIndex);
        }
      }
    }
  }
  </script>
  
  <style scoped>
  :deep(.vueperslides__arrow--prev) {
    margin-left: -60px;
  }
  :deep(.vueperslides__arrow--next) {
    margin-right: -60px;
  }
  .close-button p {
    position: fixed;
    right: 3%;
    top: 10%;
    width: 35px;
    cursor: pointer;
    font-size: 20px;
    color: white;
  }
  .close-button span {
    position: fixed;
    right: 6%;
    top: 10.3%;
  }
  .image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    margin: 0;
    max-width: unset;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    gap: 16px;
  }
  .image-content {
    height: 85vh;
    width: 58vw;
    min-height: 300px;
    top: 10%;
    position: fixed;
  }
  img {
    width: 100%;
  }
  :deep(.vueperslides__progress) {
    position: unset;
    z-index: 0;
    height: unset;
    color: unset;
  }
  :deep(.vueperslides__progress > *) {
    position: unset;
    background: unset;
    transition: unset;
  }
  :deep(.vueperslides__fractions) {
    top: 0%;
    left: unset;
    right: -20%;
    border-radius: unset;
    background: unset;
    border: unset;
    padding: 0;
    font-size: 35px;
  }
  :deep(.vueperslides__fractions::first-letter) {
    font-size: 150%;
  }
  :deep(.vueperslide) {
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (max-width: 768px) {
    .close-button p {
      top: 12%;
    }
    .close-button span {
      top: 17%;
      right: 2%;
    }
    :deep(.vueperslides__fractions) {
      top: 22%;
      right: -30%;
      font-size: 30px;
    }
  }
  </style>
  