<template>
    <b-navbar :class="navbarClasses" class="w-100" toggleable="lg">
        <div class="container-fluid">
            <b-navbar-brand class="d-block d-lg-none">
                <div class="nav-link pt-0">
                    <img class="img-fluid" :src="logoSrc" alt="Logo"
                        title="Logo" />
                </div>
            </b-navbar-brand>
            <b-collapse class="nav-menu justify-content-between d-none d-lg-flex flex-column " is-nav>
                <b-navbar-brand class="mt-4">
                    <div class="nav-link pt-0">
                        <img class="img-fluid" :src="logoSrc" alt="Logo" title="Logo" />
                    </div>
                </b-navbar-brand>
                <b-navbar-nav class="ml-auto fw-bold mt-4" :class="opacityClass">
                    <b-nav-form v-for="link in navLinks" :key="link.id">
                        <router-link :class="textClass" :to="link.to" active-class="active" exact>{{ link.label }}</router-link>
                    </b-nav-form>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto fw-bold" :class="{ 'lang-opacity': $route.name }">
                    <!-- <b-nav-form>
                        <a :class="textClass" href="#" @click.prevent="setLocale('pt')">{{$t("header.pt")}}</a>
                        <a :class="textClass" href="#" @click.prevent="setLocale('en')">{{$t("header.en")}}</a>
                    </b-nav-form> -->
                </b-navbar-nav>
            </b-collapse>
            <b-button class="d-lg-none p-0" id="navbar-toggle" v-b-toggle.sidebar-right variant="transparent" @click="toggleMobileMenu">
                <b-icon icon="list" :variant="iconVariant"></b-icon>
            </b-button>
            <b-sidebar class="w-100" id="sidebar-right" title="Sidebar" right no-header bg-variant="no-light">
                <template #default="{ hide }">
                    <SideMenu @close="hide"></SideMenu>
                </template>
            </b-sidebar>
        </div>
    </b-navbar>
</template>

<script>
import SideMenu from '@/components/menus/SideMenu.vue';

export default {
    components: {
        SideMenu
    },
    props: {
        customStyles: {
            type: Object,
            default: () => ({
                background: 'navbar-default-background',
                text: 'navbar-default-text'
            })
        }
    },
    data() {
        return {
            navLinks: [
                { id: 1, to: { name: 'HomePage' }, label: this.$t("header.home").toUpperCase() },
                { id: 2, to: { name: 'StaticInfos', params: { slug: 'projeto' } }, label: this.$t("header.projeto").toUpperCase() },
                { id: 3, to: { name: 'StaticInfos', params: { slug: 'terroir' } }, label: this.$t("header.terroir").toUpperCase() },
                { id: 4, to: { name: 'OliveOil' }, label: this.$t("header.azeites").toUpperCase() },
                { id: 5, to: { name: 'StaticInfos', params: { slug: 'gourmetSpace' } }, label: this.$t("header.gourmetSpace").toUpperCase() },
                { id: 6, to: { name: 'Gallery' }, label: this.$t("header.galeria").toUpperCase() },
                { id: 7, to: { name: 'Contact' }, label: this.$t("header.contacto").toUpperCase() }
            ]
        };
    },
    computed: {
        navbarClasses() {
            return [this.customStyles.background];
        },
        textClass() {
            return this.customStyles.text;
        },
        opacityClass() {
            return {
                'opacity-active': this.$route.name === 'TeamDetail' || this.$route.name === 'OliveOilDetail' || 
                    (this.$route.name === 'StaticInfos' && (this.$route.params.slug === 'legal' || this.$route.params.slug === 'qualidade'))
            };
        },
        logoSrc() {
            return this.$route.name === 'OliveOilDetail' || this.$route.name === 'TeamDetail' || this.$route.name === 'Gallery' || 
                (this.$route.name === 'StaticInfos' && (this.$route.params.slug === 'legal' || this.$route.params.slug === 'qualidade'))
                ? require('@/assets/img/logo.png')
                : require('@/assets/img/logo.png');
        },
        iconVariant() {
            return this.$route.name === 'TeamDetail'
            ? 'black' 
            : 'white';
        }
    },
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale;
            localStorage.setItem('locale', locale);
            window.location.href = '/';
        },
        toggleMobileMenu () {
            document.body.classList.add('overflow-hidden');
        }
    }
};
</script>

<style scoped>
/* Background and text colors for the default navbar */
.navbar-default-background {
    background-color: transparent;
}

.navbar-default-text {
    color: #fff;
}

.navbar-default-text.active {
    color: #fff;
}

/* Styles for pages with opacity active class */
.opacity-active a:not(.active),
.lang-opacity a:not(.active) {
    opacity: 1 !important;
}

/* General navbar styles */
.form-inline .form-inline a {
    font-size: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    letter-spacing: .1rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}

.navbar-brand {
    width: auto;
    height: 70px;
}

.navbar-brand img {
    width: auto;
    height: 64px;
}

.form-inline {
    display: flex;
}

:deep(.b-sidebar) {
    width: 100%;
}

/* Specific navbar styles for active */
.form-inline a:not(.active) {
    opacity: 0.6;
}

.form-inline a:active,
.form-inline a:hover {
    opacity: 1;
}

.btn-check:focus+.btn,
.btn:focus {
    box-shadow: none !important;
}

#navbar-toggle {
    font-size: 2rem;
}

/* Media query for smaller screens */
@media (max-width: 992px) {

    #navbar-toggle {
        font-size: 1.5rem;
    }

    .navbar-brand img {
        height: 50px;
    }
}
</style>
