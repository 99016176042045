import { STATICINFOS_SERVICE } from "@/services/staticInfos.service.js";
import router from '@/router/index';

const state = { listOutput: null, detailOutput: null };

const actions = {
    getList({ dispatch, commit }, inputGetStaticInfos) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        STATICINFOS_SERVICE.list(inputGetStaticInfos)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetails({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('listDetailRequest');
        
        STATICINFOS_SERVICE.getDetail(slug)
            .then(
                output => {
                    commit('listDetailSuccess', output);
                },
                error => {
                    commit('listDetailFailure', error);
                    dispatch('alert/error', error, { root: true });
                    router.push({ name: '404' });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        setTimeout(() => state.listOutput = output, 1000);
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Detail */
    listDetailRequest(state) {
        state.detailOutput = null;
    },
    listDetailSuccess(state, output) {
        // state.detailOutput = output
        setTimeout(() => state.detailOutput = output, 1000);
    },
    listDetailFailure(state) {
        state.detailOutput = false;
    },
};

export const staticInfos = {
    namespaced: true,
    state,
    actions,
    mutations
};