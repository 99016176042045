import { SETTINGS_SERVICE } from "@/services/settings.service.js";

const settingsOutput = JSON.parse(localStorage.getItem('settings'));
const state = settingsOutput
    ? { settingsOutput: settingsOutput }
    : { settingsOutput: { data: [] }};


const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');

        // const isLocal = localStorage.getItem('settings');
        // if(!isLocal){
            SETTINGS_SERVICE.list()
                .then(
                    output => {
                        commit('listSuccess', output);
                    },
                    error => {
                        commit('listFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
            // }
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.settingsOutput = null;
    },
    listSuccess(state, output) {
        state.settingsOutput = output;
        // localStorage.setItem('settings', JSON.stringify(state.settingsOutput.data));

    },
    listFailure(state) {
        state.settingsOutput = false;
    },
};

export const settings = {
    namespaced: true,
    state,
    actions,
    mutations
};