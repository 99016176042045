<template>
  <!-- Menu -->
  <div class="navbar-container">
    <div class="navbar-header">
      <div class="logo-side-menu">
        <img src="@/assets/img/logo.png" alt="Logo" title="Logo" />
      </div>
      <button class="close-btn" @click="toggleMobileMenu" aria-label="Close menu">
        <img class="close-icon" src="@/assets/svg/sideMenu/close_white.svg" alt="Close menu" />
      </button>
    </div>
    <div class="navbar">
      <ul class="nav-list">
        <li v-for="(item, index) in menuItems" :key="index" class="nav-item">
          <router-link class="nav-link" :to="item.route" active-class="active" exact>{{ item.label }}</router-link>
        </li>
        <li v-if="$i18n.locale !== 'pt'" class="nav-item">
          <a class="nav-link" href="#" @click.prevent="setLocale('pt')">{{ $t('header.pt') }}</a>
        </li>
        <li v-if="$i18n.locale !== 'en'" class="nav-item">
          <a class="nav-link" href="#" @click.prevent="setLocale('en')">{{ $t('header.en') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        { label: this.$t("sideMenu.home").toUpperCase(), route: { name: 'HomePage' } },
        { label: this.$t("sideMenu.projeto").toUpperCase(), route: { name: 'StaticInfos', params: { slug: 'projeto' } } },
        // { label: this.$t("sideMenu.terroir").toUpperCase(), route: { name: 'StaticInfos', params: { slug: 'terroir' } } },
        { label: this.$t("sideMenu.oliveOil").toUpperCase(), route: { name: 'OliveOil' } },
        // { label: this.$t("sideMenu.enoturismo").toUpperCase(), route: { name: 'StaticInfos', params: { slug: 'enoturismo' } } },
        { label: this.$t("sideMenu.galeria").toUpperCase(), route: { name: 'Gallery' } },
        { label: this.$t("sideMenu.contacto").toUpperCase(), route: { name: 'Contact' } },
      ],
    };
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      window.location.href = '/';
    },
    toggleMobileMenu() {
      this.$emit('close');
      document.body.classList.remove('overflow-hidden');
    }
  }
};
</script>

<style scoped>
/* Navbar Container */
.navbar-container {
  background-color: var(--primary-color);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navbar Header */
.navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  height: 80px;
}
.navbar {
  min-height: calc(100vh - 80px);
}
/* Logo */
.logo-side-menu img {
  height: 70px;
  width: auto;
}

/* Close Button */
.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.close-icon {
  width: 25px;
  height: auto;
}

/* Navbar List */
.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Navbar Items */
.nav-item {
  width: 100%;
  text-align: center;
}

.nav-item .nav-link {
  display: block;
  padding: 1rem 0;
  color: var(--secondary-color);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}
.nav-item .nav-link.active {
  font-weight: bold;
}
/* Specific navbar styles for active and hover states */
.nav-item .nav-link:not(.active) {
  opacity: 0.6;
}

.nav-item .nav-link:active {
  opacity: 1;
}

/* Adjustments for smaller screens */
@media (max-width: 992px) {
  .logo-side-menu img {
    height: 50px;
  }
  .close-icon {
    width: 20px;
  }
}
</style>
