<template>
    <div class="hero">
        <div class="hero-content">
            <vueper-slides :bullets="false" fixed-height="100vh" class="img-slide" :touchable="false"
                :autoplay="autoplay">
                <vueper-slide v-for="(hero, i) in heros" :key="i">
                    <template v-slot:content>
                        <HeroThumb :heroContent="hero" />
                    </template>
                </vueper-slide>
            </vueper-slides>
            <a @click="scroll" class="arrow-container">
                <div class="arrow"></div>
                <div class="arrow"></div>
                <div class="arrow"></div>
            </a>
        </div>
    </div>
</template>


<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import HeroThumb from '@/components/HomePage/hero/HeroThumb.vue';

export default {
    props: {
        heros: {
            type: Array,
            required: true
        }
    },
    components: { VueperSlides, VueperSlide, HeroThumb },
    computed: {
        autoplay() {
            return this.heros.length >= 2;
        }
    },
    methods: {
        scroll() {
            // element which needs to be scrolled to
            var element = document.querySelector("#about");

            // scroll to element
            element.scrollIntoView();
        }
    }
}
</script>


<style scoped>
.hero {
    position: relative;
    width: 100%;
    height: 100vh;
}

.hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.img-slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

:deep(.vueperslides__arrows button) {
    z-index: 10 !important;
}

:deep(.vueperslide__title h1) {
    font-size: 4rem;
    color: white;
    width: 400px;
    font-weight: 700;
}

:deep(.vueperslide__content h5) {
    font-size: 2rem;
    color: white;
    width: 400px;
    font-weight: 700;
}

.arrow-container {
    display: block;
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(0deg);
    z-index: 1;
}

.arrow-container:hover {
    cursor: pointer;
}

.arrow-container:hover .arrow {
    top: 50%;
}

.arrow-container:hover .arrow:before {
    transform: translate(-50%, -50%) rotateZ(-30deg);
}

.arrow-container:hover .arrow:after {
    transform: translate(-50%, -50%) rotateZ(30deg);
}

.arrow {
    position: absolute;
    left: 50%;
    transition: all 0.4s ease;
}

.arrow:before,
.arrow:after {
    transition: all 0.4s ease;
    content: '';
    display: block;
    position: absolute;
    transform-origin: bottom right;
    background: #fff;
    width: 4px;
    height: 30px;
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(-45deg);
}

.arrow:after {
    transform-origin: bottom left;
    transform: translate(-50%, -50%) rotateZ(45deg);
}

.arrow:nth-child(1) {
    opacity: 0.3;
    top: 35%;
}

.arrow:nth-child(2) {
    opacity: 0.6;
    top: 55%;
}

.arrow:nth-child(3) {
    opacity: 0.9;
    top: 75%;
}
</style>