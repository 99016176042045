<template>
    <div class="vueperslide" :class="{ 'image-loaded': imageLoaded }" :style="backgroundStyle">
        <div class="d-none vueperslide__content-wrapper">
            <hr />
            <div class="vueperslide__title">
                <h1>{{ heroContent.title.toUpperCase() }}</h1>
            </div>
            <hr />
            <div class="vueperslide__content">
                <h5>{{ heroContent.content }}</h5>
            </div>
            <div class="mt-4">
                <a :href="buttonUrlPath" class="btn-border-white text-decoration-none">{{ heroContent.buttonContent
                    }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
    props: {
        heroContent: {
            type: Object
        }
    },
    data() {
        return {
            imageLoaded: false,
            backgroundImageUrl: ''
        };
    },
    computed: {
        imageUrl() {
            var relativePath = this.heroContent && this.heroContent.image ? this.heroContent.image : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);
        },
        buttonUrlPath() {
            return this.heroContent.buttonUrl ? API_HELPER.pathJoin([process.env.VUE_APP_FRONT, this.heroContent.buttonUrl]) : null;
        },
        backgroundStyle() {
            return {
                backgroundImage: this.backgroundImageUrl ? `url(${this.backgroundImageUrl})` : ''
            };
        }
    },
    watch: {
        imageUrl: {
            handler(newUrl) {
                if (newUrl) {
                    const img = new Image();
                    img.onload = () => {
                        this.backgroundImageUrl = newUrl;
                        this.imageLoaded = true;
                    };
                    img.src = newUrl;
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style scoped>
.vueperslide {
    position: relative;
}

.vueperslide::before {
    content: "";
    width: 100%;
    height: 30%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, #000, rgba(84, 84, 84, 0));
    mix-blend-mode: multiply;
    opacity: 0;
    z-index: 9;
    transition: opacity 0.5s ease;
}

.vueperslide.image-loaded::before {
    opacity: .45;
}

hr:not([size]) {
    height: 8px;
    width: 70px;
}

hr {
    opacity: 1;
    color: white;
}
</style>
