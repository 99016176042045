<template>
  <div class="intro-banner" :class="{ 'image-loaded': imageLoaded }">
    <div class="image" :style="imageStyle"></div>
    <div class="container">
      <div class="logo">
        <img loading="lazy" :src="logo" alt="Cooperativa Agrícola Tabuaço" />
      </div>
      <div class="content">
        <div class="oliveOilOrigin">{{ $t("welcomeBannerPhraseDouro") }}</div>
        <div class="mt-5 d-flex flex-column gap-2">
          <span class="welcome-style">{{ $t("welcome") }}</span>
          <button @click="isConfirmation" class="btn-enter mt-3 mb-3">{{ $t("enter") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      imageLoaded: false,
      imageUrl: require("@/assets/img/welcome-banner.jpg")
    };
  },
  mounted() {
    this.preloadImage(this.imageUrl);
  },
  methods: {
    isConfirmation() {
      let inHalfADay = 0.5;
      Cookies.set("ageVerified", "true", {
        expires: inHalfADay,
        secure: true
      });
      this.$emit('isClick');
    },
    preloadImage(url) {
      const img = new Image();
      img.onload = () => {
        this.imageLoaded = true;
      };
      img.src = url;
    }
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.imageUrl})`
      };
    }
  }
};
</script>

<style scoped>
.intro-banner {
  height: 100vh;
  color: #fff;
  position: relative;
}

.intro-banner::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, #000, rgba(84, 84, 84, 0.2));
  z-index: 5;
  min-height: 100vh;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.intro-banner.image-loaded::before {
  opacity: 1;
}

.intro-banner .container {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  text-align: center;
}

.intro-banner .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  min-height: 100vh;
}

.intro-banner .logo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55%;
  margin-left: auto;
  margin-right: auto;
}

.intro-banner .logo img {
  display: block;
  margin: 0 auto;
  height: 200px;
  width: auto;
}

img {
  max-width: 100%;
  height: auto;
}

.intro-banner .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.intro-banner h2 {
  font-size: 2.5rem;
  line-height: 1.2;
}

.intro-banner .oliveOilOrigin {
  font-size: 30px;
  line-height: 1.4;
  text-align: center;
  margin-top: 13.33333px;
  color: var(--secondary-color);
  font-family: cursive;
}
.btn-enter{
  color: var(--secondary-color);
  border: none;
  background-color: transparent;
  font-size: 20px;
}
.btn-enter:hover{
  opacity: 0.8;
  font-size: 25px;
}
.welcome-style{
  font-size: 20px;
  color: white;
}

@media screen and (max-width: 992px) {
  .intro-banner {
    height: 620px;
  }

  .intro-banner .logo {
    bottom: 60%;
  }

  .intro-banner .content {
    top: 45%;
  }

  .intro-banner h2 {
    font-size: 34px;
    line-height: 1.2;
  }
}
</style>
