/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const PRIZES_SERVICE = {
    list,
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Prizes";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: {"Content-Type":"application/json"},

    }; 

    // Process API call
    return API_HELPER.apiCall(url,queryParams, requestOptions);
}
