import { MEMBERS_SERVICE } from "@/services/members.service.js";

const state = { listOutput: null, detailOutput: null};

const actions = {
    getList({ dispatch, commit }, inputGetMembers) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        MEMBERS_SERVICE.list(inputGetMembers)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetails({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('listDetailRequest');
        
        MEMBERS_SERVICE.getDetail(slug)
            .then(
                output => {
                    commit('listDetailSuccess', output);
                },
                error => {
                    commit('listDetailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Detail */
    listDetailRequest(state) {
        state.detailOutput = null;
    },
    listDetailSuccess(state, output) {
        setTimeout(() => state.detailOutput = output, 1000);
    },
    listDetailFailure(state) {
        state.detailOutput = false;
    }
};

export const members = {
    namespaced: true,
    state,
    actions,
    mutations
};